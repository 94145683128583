export const genres = [
    
    "Seinen",
    "Supernatural",
    "Comedy",
    "Ecchi",
    "Fantasy",
    "Harem",
    "Romance",
    "School",
    "Sci-Fi",
    "Action",
    "Shounen",
    "Super Power",
    "Drama",
    "Mystery", 
    "Adventure",
    "Slice of Life",
    "Magic",
    "Horror",
    "Psychological",
    "Cyberpunk",
    "Game",
    "Demons",
    "Josei",
    "Martial Arts",
    "Kids",
    "Historical",
    "Hentai",
    "Isekai",
    "Military",
    "Mecha",
    "Music",
    "Parody",
    "Police",
    "Post-Apocalyptic",
    "Reverse Harem",
    "Shoujo",
    "Shoujo-ai",
    "Shounen-ai",
    "Space",
    "Sports",
    "Tragedy",
    "Vampire",
    "Yuri",
    "Yaoi"
]