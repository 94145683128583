import React,{createContext, useState} from 'react'
import { useNotification } from '../../hooks/themeHook';

export const SearchContext = createContext();


let timeoutId;
const debounce = (func,delay)=>{
 return (...args)=>{
  if(timeoutId) clearTimeout(timeoutId)
  
  timeoutId = setTimeout(() => {
     func.apply(null,args)
   }, delay);
 }
}

function SearchProvider({children}) {
    const [searching,setSearching] = useState(false)
    const [results,setResults] = useState([])
    const [resultNotFound,setResultNotFound] = useState(false)

    const {updateNotification} = useNotification

  

    const search = async (method,query,updaterFun)=>{
       const {error,results} = await method(query)
       if(error) return updateNotification('error',error)
       
       if(!results.length) {
        setResults([])
        updaterFun && updaterFun([])
        return setResultNotFound(true)
      }
      setResultNotFound(false)
       
       setResults(results)
       updaterFun && updaterFun([...results])
       
       console.log(updaterFun);
    }
    
    const debounceFunc = debounce(search,300)

    const handleSearch = (method,query,updaterFun)=>{
        setSearching(true);
        if(!query.trim()){
            updaterFun && updaterFun([])
           return resetSearch()
        }

        debounceFunc(method,query,updaterFun)
    }

    const resetSearch = () =>{
        setSearching(false);
        setResults([])
        setResultNotFound(false)
    }

  return (
    <SearchContext.Provider value={{handleSearch,resetSearch,searching,resultNotFound,results}}> {children} </SearchContext.Provider>
  )
}

export default SearchProvider